////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import {
  EmailPreferences,
  ExportReportLocationType,
  AvtV1ExportReportOptions,
  FileDestinationNamingType,
  Job,
  JobScheduleType,
  Schedule, JobFile, JobDirectory
} from '../../clients/Classes';
import {Task} from '../Task';
import {FileStructureTranslator} from './FileStructureTranslator';
import {CronTranslator} from './CronTranslator';

export class TaskTranslator {
  static GetTask(job: Job): Task {
    const task = new Task();
    task.Id = job.id;
    task.Name = job.name;
    task.HubId = job.hubId;
    task.ProjectId = job.projectId;
    task.CustomerId = job.customerId;
    task.UserId = job.userId;
    let hubName: string | undefined;
    let projectName: string | undefined;
    job.models!.forEach(apiModel => {
      task.Models.push(FileStructureTranslator.GetFile(apiModel));
      if (apiModel instanceof JobFile) {
        hubName = apiModel.hubName;
        projectName = apiModel.projectName;
      }
    });
    job.directories!.forEach(apiDirectory => {
      task.Directories.push(FileStructureTranslator.GetDirectory(apiDirectory, false));
      if (apiDirectory instanceof JobDirectory) {
        hubName = apiDirectory.hubName;
        projectName = apiDirectory.projectName;
      }
    });
    task.Checkset = job.checkSet;
    task.RawStatus = job.status!;
    task.IsPaused = job.schedule != null && job.schedule.isPaused!;
    switch (job.scheduleType) {
      case JobScheduleType.None:
        break;
      case JobScheduleType.OnceNow:
        task.Trigger = 'OnceNow';
        break;
      case JobScheduleType.OnceLater:
        task.Trigger = 'OnceLater';
        task.StartDate = job.nextRun == null || job.nextRun.startTime! < new Date()
          ? new Date(new Date().getTime() + (15 * 60 * 1000))
          : job.nextRun.startTime!;
        break;
      case JobScheduleType.Recurring:
        task.Trigger = 'Recurring';
        task.RecurrenceSettings = CronTranslator.GetSettings(job.schedule!);
        task.StartDate = job.schedule!.startDate!;
        break;
      case JobScheduleType.OnPublish:
        task.Trigger = 'OnPublish';
        break;
    }

    if (job.exportOptions == null) {
      task.ExportLocationType = ExportReportLocationType.ModelDirectory;
      task.ExportDestinationNaming = FileDestinationNamingType.AppendTimestamp;
    } else {
      task.ExportExcel = job.exportOptions.excel!;
      task.ExportHtml = job.exportOptions.html!;
      task.ExportLists = job.exportOptions.exportLists!;
      task.CombineExcel = job.exportOptions.combineExcel!;
      switch (job.exportOptions.locationType) {
        case ExportReportLocationType.None:
          break;
        case ExportReportLocationType.ModelDirectory:
          task.ExportLocationType = ExportReportLocationType.ModelDirectory;
          break;
        case ExportReportLocationType.ModelSubdirectory:
          task.ExportLocationType = ExportReportLocationType.ModelSubdirectory;
          break;
        case ExportReportLocationType.OtherDirectory:
          task.ExportLocationType = ExportReportLocationType.OtherDirectory;
          break;
      }
      task.ExportProjectId = job.exportOptions.locationProjectId;
      task.ExportDirectoryId = job.exportOptions.locationDirectoryId;
      task.ExportDestinationNaming = job.exportOptions.destinationNamingType!;
    }

    task.HubName = hubName;
    task.ProjectName = projectName;

    task.EmailOnCompletion = job.emailPreferences != null && job.emailPreferences.emailOnCompletion!;
    task.AttachExportFiles = job.emailPreferences != null && job.emailPreferences.attachExportFiles!;

    task.LastRun = job.lastRun?.startTime;
    task.NextRun = job.nextRun?.startTime;

    task.LastRunOrCreated = job.lastRunOrCreatedTime;

    task.RevitVersion = job.revitVersion!;

    return task;
  }

  static GetEmailPreferences(task: Task): EmailPreferences {
    return new EmailPreferences({
      emailOnCompletion: task.EmailOnCompletion,
      attachExportFiles: task.AttachExportFiles
    });
  }

  static GetExportOptions(task: Task): AvtV1ExportReportOptions {
    return new AvtV1ExportReportOptions({
      excel: task.ExportExcel,
      html: task.ExportHtml,
      locationProjectId: task.ExportProjectId,
      locationDirectoryId: task.ExportDirectoryId,
      locationType: task.ExportLocationType,
      destinationNamingType: task.ExportDestinationNaming,
      exportLists: task.ExportLists,
      combineExcel: task.CombineExcel
    });
  }

  static GetScheduleFromTask(task: Task): Schedule | undefined {
    switch (task.Trigger) {
      case 'OnceNow':
      case 'OnceLater':
      case 'OnPublish':
        return undefined;
      case 'Recurring':
        const end = task.RecurrenceSettings!.EndType === 'Date' ? task.RecurrenceSettings!.EndDate : undefined;
        const runs = task.RecurrenceSettings!.EndType === 'Occurrences' ? task.RecurrenceSettings!.MaxRuns : 0;
        return new Schedule({
          cronString: CronTranslator.GetCronString(task.RecurrenceSettings!),
          startDate: task.RecurrenceSettings!.StartDate,
          endDate: end,
          maxRuns: runs
        });
    }
  }
}
