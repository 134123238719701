////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import {TasksActions} from "../../Enums";
import {TasksState} from "../states/TasksState";

export function reducer(state: TasksState, action: { type: TasksActions, payload: any }): TasksState {
  switch (action.type) {
    case TasksActions.tasks:
      return {...state, tasks: action.payload};
    case TasksActions.loading:
      return {...state, loading: action.payload};
    case TasksActions.loadingMoreData:
      return {...state, loadingMoreData: action.payload};
    case TasksActions.hasMoreData:
      return {...state, hasMoreData: action.payload};
    case TasksActions.filteredTasks:
      return {...state, filteredTasks: action.payload};
    case TasksActions.multipleActions:
      return {...state, ...action.payload};
    case TasksActions.filterOpen:
      return {...state, filterOpen: action.payload};
    case TasksActions.canCancelLoad:
      return {...state, canCancelLoad: action.payload};
  }
}
