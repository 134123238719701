////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import {
  AvtJobDataEntry,
  AvtJobRunDataEntry,
  AvtV1ExportReportOptions,
  AvtV1ReportCheckDto,
  AvtV1ReportCheckResultDto,
  AvtV1ReportDto,
  AvtV1ReportElementDataDto,
  AvtV1ReportFileDto,
  AvtV1ReportSectionDto,
  CheckSet,
  CheckSetSource,
  CustomerInfo,
  CustomerUsageDataBase,
  EmailPreferences,
  ExportReportLocationType,
  FileOrDirectoryType,
  FileType,
  Hub,
  HubType,
  Job,
  JobDirectory,
  JobFile,
  JobRun,
  JobRunItemStatusType,
  JobRunStatusType,
  JobScheduleType,
  JobStatusType,
  JobType,
  JobUsageDataBase,
  OutgoingWebhook,
  OutgoingWebhookEvent,
  OutgoingWebhookScopeType,
  OverallUsageData,
  Project,
  ProjectType,
  ProjectWiseConfiguration,
  ProjectWiseCredential,
  ProjectWiseCredentialWithSecret,
  ReportCheckResultCondition,
  ReportCheckType,
  ReportHeadingDto,
  RevitVersion,
  Schedule,
  StorageType,
  UsageDataLevel,
  UsageSummary,
  User,
  UserProfileImages,
  UserUsageDataBase
} from '../clients/Classes';
import {RecurrenceEndType, RecurrencePeriod, TaskTrigger} from '../dataModel/Enums';
import {GenerateRandomString, GetDateWithOffset} from '../Utility';
import {CronTranslator} from '../dataModel/translators/CronTranslator';
import {RecurrenceSettings} from '../dataModel/RecurrenceSettings';
import {AvtReportDtoBase, AvtReportSummaryDto} from "../clients/V2Classes";

export class SampleData {
  public readonly sampleJobs: Job[];
  public readonly sampleReportSummaries: AvtReportSummaryDto[];
  public readonly sampleReports: AvtV1ReportDto[];
  public readonly sampleHubs: Hub[];
  public readonly sampleProjects: Project[];
  public readonly sampleFiles: JobFile[];
  public readonly sampleDirectories: JobDirectory[];
  public readonly reportModel: JobFile;
  public readonly sampleCheckSets: CheckSet[];
  public readonly user: User;
  public readonly sampleUsageSummary: UsageSummary;
  public readonly sampleDetailedUsage: AvtJobDataEntry[];

  public readonly sampleOutgoingWebhooks: { hook: OutgoingWebhook, secret: string }[];
  public readonly sampleOutgoingWebhooksHistory: OutgoingWebhookEvent[];

  public readonly customers: CustomerInfo[];

  public readonly projectWiseCredentials: ProjectWiseCredentialWithSecret[];
  public readonly projectWiseConfigurations: ProjectWiseConfiguration[];

  constructor() {
    this.customers = [];

    for (let i = 0; i < 50; i++) {
      this.customers.push(this.GetCustomer(`Sample Customer ${i}`));
    }

    this.user = this.GetUser(this.customers[0]);
    const projectData = this.GetProjects();
    this.reportModel = projectData.files[0];
    this.sampleHubs = projectData.hubs;
    this.sampleProjects = projectData.projects;
    this.sampleDirectories = projectData.directories;
    this.sampleFiles = projectData.files;
    this.sampleJobs = this.GetJobs();
    this.sampleUsageSummary = this.GetUsageSummary(5, 2);
    this.sampleDetailedUsage = this.CreateDetailedUsage(100);
    this.sampleCheckSets = this.CreateSampleCheckSets(40);
    const reportData = this.CreateSampleReports();
    this.sampleReports = reportData.reports;
    this.sampleReportSummaries = reportData.summaries;
    this.sampleOutgoingWebhooks = [];
    this.sampleOutgoingWebhooksHistory = [];
    this.customers.forEach(c => {
      const hooks = this.GetWebhooks(c, 6);
      hooks.forEach(h => this.sampleOutgoingWebhooks.push(h));
    });

    this.projectWiseCredentials = this.CreateProjectWiseCredentials(150);
    this.projectWiseConfigurations = [];
    this.projectWiseCredentials.forEach(c =>
      this.projectWiseConfigurations.push(...this.CreateProjectWiseConfigurations(c, 2))
    );
  }

  public GetId(): string {
    return GenerateRandomString(12);
  }

  // Overall data populate
  private GetCustomer(name: string): CustomerInfo {
    return new CustomerInfo({
      id: this.GetId(),
      name: name,
      emailDomain: `@${name}.com`,
      allowAllUsers: false,
      validEmails: [
        `user@${name.toLowerCase()}.com`,
        `user2@${name.toLowerCase()}.com`,
      ]
    });
  }

  private GetWebhooks(customer: CustomerInfo, quantity: number): { hook: OutgoingWebhook, secret: string }[] {
    const hooks: { hook: OutgoingWebhook, secret: string }[] = [];

    for (let i = 0; i < quantity; i++) {
      const newHook = new OutgoingWebhook({
        id: this.GetId(),
        userId: this.user.id,
        url: `https://${customer.name}.com/hooks/${i}`,
        scope: OutgoingWebhookScopeType.User,
        customerId: customer.id,
        isEnabled: i < 4
      });

      newHook.lastEvent = this.GetWebhookEvent(newHook, 1, i > 1, i === 0);

      hooks.push({
        hook: newHook,
        secret: GenerateRandomString(16)
      });
    }

    return hooks;
  }

  private GetWebhookEvent(hook: OutgoingWebhook, eventNumber: number, success: boolean, stillTrying: boolean): OutgoingWebhookEvent {
    return new OutgoingWebhookEvent({
      id: this.GetId(),
      webhookId: hook.id,
      webhookEventNumber: eventNumber,
      url: hook.url,
      scope: hook.scope,
      scopeId: hook.scopeId,
      success: success,
      isComplete: !stillTrying,
      statusCode: 200,
      retryCount: 2,
      sendAttempts: [],
      sendAttemptsLoaded: false,
    });
  }

  private GetUser(customer: CustomerInfo): User {
    const images = new UserProfileImages();
    images.sizeX20 = 'https://revolutiondesign.biz/wp-content/uploads/2017/09/keynote-manager-product.png';
    images.sizeX40 = 'https://revolutiondesign.biz/wp-content/uploads/2017/09/keynote-manager-product.png';
    images.sizeX50 = 'https://revolutiondesign.biz/wp-content/uploads/2017/09/keynote-manager-product.png';
    images.sizeX58 = 'https://revolutiondesign.biz/wp-content/uploads/2017/09/keynote-manager-product.png';
    images.sizeX80 = 'https://revolutiondesign.biz/wp-content/uploads/2017/09/keynote-manager-product.png';
    images.sizeX120 = 'https://revolutiondesign.biz/wp-content/uploads/2017/09/keynote-manager-product.png';
    images.sizeX160 = 'https://revolutiondesign.biz/wp-content/uploads/2017/09/keynote-manager-product.png';
    images.sizeX176 = 'https://revolutiondesign.biz/wp-content/uploads/2017/09/keynote-manager-product.png';
    images.sizeX240 = 'https://revolutiondesign.biz/wp-content/uploads/2017/09/keynote-manager-product.png';
    images.sizeX360 = 'https://revolutiondesign.biz/wp-content/uploads/2017/09/keynote-manager-product.png';

    const user = new User();
    user.init({
      firstName: 'First',
      lastName: 'Last',
      email: 'someone@somewhere.com',
      id: '12345',
      is2FAEnabled: false,
      isEmailVerified: true,
      isAdmin: true,
      roles: ['bit_admin'],
      username: 'sample username',
      profileImages: images,
      customer: customer
    });
    return user;
  }

  private GetProjects(): { hubs: Hub[], projects: Project[], files: JobFile[], directories: JobDirectory[] } {
    const returnData: { hubs: Hub[], projects: Project[], files: JobFile[], directories: JobDirectory[] } = {
      hubs: [],
      projects: [],
      directories: [],
      files: []
    };

    const hub = new Hub({
      id: this.GetId(),
      hubType: HubType.B360,
      name: `Hub 0`,
      region: 'region 1'
    });
    returnData.hubs.push(hub);

    for (let i = 0; i < 300; i++) {
      const project = new Project({
        hubId: hub.id,
        id: this.GetId(),
        rootDirectoryId: this.GetId(),
        name: `Test Project ${i}`,
        imageUrl: 'https://revolutiondesign.biz/wp-content/uploads/2017/10/Icon_Revolution.png',
        projectType: i === 0 ? ProjectType.A360 : ProjectType.ACC,
        hubName: hub.name,
        hubRegion: hub.region,
      });
      returnData.projects.push(project);

      const root = new JobDirectory();
      root.init({
        hubId: project.hubId,
        hubName: project.hubName,
        hubType: project.hubType,
        region: project.hubRegion,
        projectName: project.name,
        projectType: project.projectType,
        id: project.rootDirectoryId,
        name: 'Project Files',
        displayName: 'Project Files',
        isDirectory: true,
        lastUpdated: this.GetRandomDate(),
        lastUpdatedBy: 'Some Updater',
        projectId: project.id,
        isHidden: false
      });

      returnData.directories.push(root);
      const items = this.GenerateDirectoryNode(project, 0, 3, root, 3);
      items.forEach(item => {
        if (item instanceof JobFile) {
          returnData.files.push(item);
        } else {
          returnData.directories.push(item);
        }
      });
    }

    return returnData;
  }

  private GetJobs(): Job[] {
    const jobs: Job[] = [];
    const futureDate = GetDateWithOffset(5);
    const pastDate = GetDateWithOffset(-5);

    const scheduledTask = this.CreateSampleJob('Scheduled Sample',
      false, 'OnceLater',
      'Daily', 'None', futureDate, undefined, -1,
      this.GetRandomDate(), this.GetRandomDate(false), JobStatusType.Scheduled);
    scheduledTask.models!.push(this.GetSampleModel('Sample Model 1.rvt'));
    scheduledTask.models!.push(this.GetSampleModel('Sample Model 2.rvt'));
    scheduledTask.revitVersion = RevitVersion.V2018;
    jobs.push(scheduledTask);

    const pausedTask = this.CreateSampleJob('Paused Sample',
      true, 'Recurring',
      'Daily', 'None', this.GetRandomDate(), futureDate, -1,
      this.GetRandomDate(), this.GetRandomDate(false), JobStatusType.Paused);
    pausedTask.models!.push(this.GetSampleModel('Sample Model 1.rvt'));
    pausedTask.revitVersion = RevitVersion.V2025;
    jobs.push(pausedTask);

    const errorTask = this.CreateSampleJob('Error Sample',
      true, 'Recurring',
      'Daily', 'None', this.GetRandomDate(), futureDate, -1,
      this.GetRandomDate(), this.GetRandomDate(false), JobStatusType.Error);
    errorTask.models!.push(this.GetSampleModel('Sample Model 1.rvt'));
    errorTask.revitVersion = RevitVersion.V2020;
    jobs.push(errorTask);

    const errorCompleteTask = this.CreateSampleJob('Error Complete Sample',
      false, 'OnceLater',
      undefined, 'Date', pastDate, undefined, -1,
      this.GetRandomDate(), undefined, JobStatusType.Error);
    errorCompleteTask.models!.push(this.GetSampleModel('Sample Model 1.rvt'));
    errorCompleteTask.revitVersion = RevitVersion.V2021;
    jobs.push(errorCompleteTask);

    const partialCompleteTask = this.CreateSampleJob('Partially Completed Sample',
      false, 'OnceLater',
      undefined, 'Date', pastDate, undefined, -1,
      this.GetRandomDate(), undefined, JobStatusType.PartiallyCompleted);
    partialCompleteTask.models!.push(this.GetSampleModel('Sample Model 1.rvt'));
    partialCompleteTask.revitVersion = RevitVersion.V2022;
    jobs.push(partialCompleteTask);

    const completeTaskSingle = this.CreateSampleJob('Completed Single Sample',
      false, 'OnceLater',
      undefined, 'Date', pastDate, undefined, -1,
      this.GetRandomDate(), undefined, JobStatusType.Scheduled);
    completeTaskSingle.models!.push(this.GetSampleModel('Sample Model 1.rvt'));
    completeTaskSingle.revitVersion = RevitVersion.V2023;
    jobs.push(completeTaskSingle);

    const completeTaskEndDate = this.CreateSampleJob('Completed End Date Sample',
      false, 'Recurring',
      'Daily', 'Date', pastDate, GetDateWithOffset(-1), -1,
      this.GetRandomDate(), undefined, JobStatusType.Scheduled);
    completeTaskEndDate.models!.push(this.GetSampleModel('Sample Model 1.rvt'));
    completeTaskEndDate.revitVersion = RevitVersion.V2023;
    jobs.push(completeTaskEndDate);

    const onPublishTask = this.CreateSampleJob('On Publish Sample',
      false, 'OnPublish',
      'Daily', 'Date', pastDate, GetDateWithOffset(-1), -1,
      this.GetRandomDate(), undefined, JobStatusType.Scheduled);
    onPublishTask.models!.push(this.GetSampleModel('Sample Model 1.rvt'));
    onPublishTask.revitVersion = RevitVersion.V2023;
    jobs.push(onPublishTask);

    for (let i = 0; i < 40; i++) {
      const task = this.CreateSampleJob(`Extra Sample ${i}`,
        false, 'OnceLater',
        'Daily', 'None', futureDate, undefined, -1,
        this.GetRandomDate(), this.GetRandomDate(false), JobStatusType.Scheduled);
      task.models!.push(this.GetSampleModel('Sample Model 1.rvt'));
      task.models!.push(this.GetSampleModel('Sample Model 2.rvt'));
      jobs.push(task);
    }

    return jobs;
  }

  private GetUsageSummary(countPerLevel: number, runsPerJob: number): UsageSummary {
    const summary = new UsageSummary();
    const data = new OverallUsageData();
    data.init({
      jobRunCount: runsPerJob * countPerLevel * countPerLevel * countPerLevel,
      level: UsageDataLevel.All,
      customerUsageDatas: [],
      runTimeSeconds: 300
    });

    for (let i = 0; i < countPerLevel; i++) {
      const customer = this.CreateSampleCustomerUsage(countPerLevel, runsPerJob);
      data.customerUsageDatas!.push(customer);
    }
    summary.data = data;
    return summary;
  }

  // Individual object creation
  private CreateSampleJob(name: string,
                          isPaused: boolean,
                          trigger: TaskTrigger,
                          recurrence: RecurrencePeriod | undefined,
                          endType: RecurrenceEndType,
                          startDate: Date,
                          endDate: Date | undefined,
                          maxRuns: number,
                          lastRun: Date,
                          nextRun: Date | undefined,
                          status: JobStatusType): Job {
    let schedule: Schedule | undefined;
    let scheduleType: JobScheduleType = JobScheduleType.None;
    switch (trigger) {
      case 'OnceNow':
        schedule = undefined;
        scheduleType = JobScheduleType.OnceNow;
        break;
      case 'OnceLater':
        schedule = new Schedule({
          cronString: '',
          startDate: startDate,
          endDate: endDate,
          maxRuns: maxRuns,
          isPaused: isPaused
        });
        scheduleType = JobScheduleType.OnceLater;
        break;
      case 'Recurring':
        const settings = new RecurrenceSettings(startDate, maxRuns);
        settings.EndDate = endDate ?? this.GetRandomDate(false);
        settings.Recurrence = recurrence ?? 'Daily';
        settings.EndType = endType;

        schedule = new Schedule({
          cronString: CronTranslator.GetCronString(settings),
          startDate: startDate,
          endDate: endDate,
          maxRuns: maxRuns,
          isPaused: isPaused
        });
        scheduleType = JobScheduleType.Recurring;
        break;
      case 'OnPublish':
        scheduleType = JobScheduleType.OnPublish;
        break;
    }

    const projectIndex = status === JobStatusType.Error ? 0 : 1;

    const checkSet = this.CreateSampleCheckSets(1)[0];

    const job = new Job();
    job.init({
      id: this.GetId(),
      name: name,
      userId: this.GetId(),
      customerId: this.GetId(),
      models: this.GetSampleModels(
        this.GetId(),
        this.sampleProjects[projectIndex].hubId!,
        this.sampleProjects[projectIndex].hubType!,
        this.sampleProjects[projectIndex].hubName!,
        this.sampleProjects[projectIndex].projectType!,
        this.sampleProjects[projectIndex].name!,
        'Some Parent',
        this.sampleProjects[projectIndex].hubRegion!,
        this.sampleProjects[projectIndex].id!,
        2
      ),
      directories: [],
      scheduleType: scheduleType,
      schedule: schedule,
      lastRun: new JobRun({
        id: this.GetId(),
        jobId: this.GetId(),
        startTime: lastRun,
        jobRunItems: []
      }),
      lastRunOrCreatedTime: lastRun,
      nextRun: new JobRun({
        id: this.GetId(),
        jobId: this.GetId(),
        startTime: nextRun,
        jobRunItems: []
      }),
      status: status,
      checkSet: checkSet,
      jobType: JobType.Standard,
      emailPreferences: new EmailPreferences({emailOnCompletion: true, attachExportFiles: true}),
      hubId: this.sampleProjects[projectIndex].hubId!,
      projectId: this.sampleProjects[projectIndex].id!,
      exportOptions: new AvtV1ExportReportOptions({
        html: true,
        locationType: ExportReportLocationType.OtherDirectory,
        locationProjectId: this.sampleProjects[0].id,
        locationDirectoryId: this.sampleDirectories.find(d => d.projectId == this.sampleProjects[0].id && d.parentId != null)?.id
      })
    });
    return job;
  }

  private GenerateDirectoryNode(project: Project,
                                currentLevel: number,
                                maxLevel: number,
                                directory: JobDirectory,
                                subFolderCount: number): (JobFile | JobDirectory)[] {
    const items: (JobFile | JobDirectory)[] = [];
    const models = this.GetSampleModels(
      directory.id!,
      project.hubId!,
      project.hubType!,
      project.hubName!,
      project.projectType!,
      project.name!,
      directory.name!,
      project.hubRegion!,
      project.id!,
      10,
      ` - ${project.name} - Level ${currentLevel}`
    );

    models.forEach(model => {
      items.push(model);
    });

    if (maxLevel <= currentLevel) {
      return items;
    }

    currentLevel++;
    for (let i = 0; i < subFolderCount; i++) {
      const subFolder = new JobDirectory();
      subFolder.init({
        hubId: project.hubId,
        hubName: project.hubName,
        hubType: project.hubType,
        region: project.hubRegion,
        projectName: project.name,
        projectType: project.projectType,
        parentName: directory.name,
        isDirectory: true,
        id: this.GetId(),
        fileOrDirectoryType: FileOrDirectoryType.ForgeDirectory,
        storageType: StorageType.Forge,
        name: directory.name === 'Project Files' ? `Sub-Folder ${i}` : `${directory.name}.${i}`,
        displayName: directory.name === 'Project Files' ? `Sub-Folder ${i}` : `${directory.name}.${i}`,
        parentId: directory.id,
        projectId: project.id,
        lastUpdated: this.GetRandomDate(),
        lastUpdatedBy: `Sample Updater ${i}`,
        isHidden: false
      });

      items.push(subFolder);

      const childItems = this.GenerateDirectoryNode(project, currentLevel, maxLevel, subFolder, subFolderCount);
      childItems.forEach(item => items.push(item));
    }

    return items;
  }

  private GetSampleModels(parentId: string, hubId: string, hubType: HubType, hubName: string, projectType: ProjectType, projectName: string, parentName: string, region: string, projectId: string, count: number = 10, nameTag: string = ''): JobFile[] {
    const models: JobFile[] = [];

    for (let i = 0; i < count; i++) {
      const model = new JobFile();
      model.init({
        hubId: hubId,
        hubType: hubType,
        hubName: hubName,
        region: region,
        projectType: projectType,
        projectName: projectName,
        parentName: parentName,
        directoryId: parentId,
        fileType: new FileType({fileDescription: 'Revit File', fileExtension: 'rvt'}),
        thumbnail: 'assets/images/Default_File.png',
        isDirectory: false,
        size: 52336,
        isLocked: false,
        pathInProject: 'some/path',
        fileNameWithExtension: `Sample Model ${i}${nameTag}.rvt`,
        id: this.GetId(),
        fileOrDirectoryType: FileOrDirectoryType.ForgeFile,
        storageType: StorageType.Forge,
        name: `Sample Model ${i}${nameTag}`,
        displayName: `Sample Model ${i}${nameTag}`,
        parentId: parentId,
        projectId: projectId,
        lastUpdated: this.GetRandomDate(),
        lastUpdatedBy: `Sample Updater ${i}`,
        isHidden: false,
        versionNumber: i,
        objectId: this.GetId(),
        isComposite: i < 3,
        derivativesId: this.GetId(),
        isProcessed: i !== 0,
        compositeParentFile: undefined
      });
      models.push(model);
    }

    return models;
  }

  private GetSampleModel(name: string): JobFile {
    const file = new JobFile();
    file.init({
      id: this.GetId(),
      name: name,
      thumbnail: 'some image path',
      lastUpdated: GetDateWithOffset(-1),
      lastUpdatedBy: 'sample user',
      versionNumber: 1,
      isDirectory: false,
      size: 153668,
      hubId: this.reportModel.hubId,
      projectId: this.reportModel.projectId,
    });
    return file;
  }

  private CreateSampleCustomerUsage(countPerLevel: number, runsPerJob: number): CustomerUsageDataBase {
    const id = this.GetId();
    const item = new CustomerUsageDataBase({
      jobRunCount: runsPerJob * countPerLevel * countPerLevel,
      customerId: id,
      level: UsageDataLevel.Customer,
      runTimeSeconds: 80,
      userUsageDatas: [],
      customerName: `Customer Name ${id}`,
    });

    for (let i = 0; i < countPerLevel; i++) {
      const job = this.CreateSampleUserUsage(countPerLevel, runsPerJob);
      item.userUsageDatas!.push(job);
    }

    return item;
  }

  private CreateSampleUserUsage(countPerLevel: number, runsPerJob: number): UserUsageDataBase {
    const id = this.GetId();
    const item = new UserUsageDataBase({
      jobRunCount: runsPerJob * countPerLevel,
      userId: id,
      username: `User ${id}`,
      level: UsageDataLevel.User,
      runTimeSeconds: 20,
      jobUsageDatas: []
    });

    for (let i = 0; i < countPerLevel; i++) {
      const job = this.CreateSampleJobUsage(runsPerJob);
      item.jobUsageDatas!.push(job);
    }

    return item;
  }

  private CreateSampleJobUsage(runsPerJob: number): JobUsageDataBase {
    const id = this.GetId();
    return new JobUsageDataBase({
      jobRunCount: runsPerJob,
      jobId: id,
      jobName: `Sample Job ${id}`,
      runTimeSeconds: 5,
      level: UsageDataLevel.Job
    });
  }

  private CreateDetailedUsage(quantity: number): AvtJobDataEntry[] {
    const usages: AvtJobDataEntry[] = [];

    for (let i = 0; i < quantity; i++) {
      const usage = new AvtJobDataEntry();
      const runDataEntry = new AvtJobRunDataEntry();
      runDataEntry.init({
        runStatus: JobRunStatusType.Success,
        jobStatus: JobStatusType.Scheduled,
        jobRunId: `jr${i}`,
        jobId: `j${i}`,
        jobName: `Sample Job ${i}`,
        customerId: this.user.customer!.id,
        customerName: this.user.customer!.name,
        userId: this.user.id,
        username: this.user.username,
        email: this.user.email,
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        sourceProjectId: `p${i}`,
        sourceHubType: HubType.B360Team,
        sourceProjectType: ProjectType.ACC,
        runCreatedDate: this.GetRandomDate(),
        runUpdatedDate: this.GetRandomDate(),
        runModifiedDate: this.GetRandomDate(),
        scheduleType: JobScheduleType.OnPublish,
        jobRunActualStartTime: this.GetRandomDate(),
        jobRunActualEndTime: this.GetRandomDate(),
        jobRunScheduledStartTime: this.GetRandomDate(),
        description: 'this is a really long description that I am putting in here because I need a long value.  A long value will trigger the truncation in the detail object and will let me see how this works when I am trying to test the UI functionality.'
      });
      usage.init({
        jobRunDataEntry: runDataEntry,
        id: i.toString(),
        modelStatus: JobRunItemStatusType.Success,
        sourceModelId: `m${i}`,
        sourceFileNameWithExtension: `FileName${i}.ext`,
        sourceDirectoryId: `d${i}`
      });

      usages.push(usage);
    }

    return usages;
  }

  private CreateSampleReports(): { reports: AvtV1ReportDto[], summaries: AvtReportSummaryDto[] } {
    const summaries: AvtReportSummaryDto[] = [];
    const reports: AvtV1ReportDto[] = [];

    for (let i = 0; i < 50; i++) {
      const checkSet = i < 5 ? this.sampleCheckSets[0] : this.sampleCheckSets[1];
      const summary = new AvtReportSummaryDto({
        summaryInfo: new AvtReportDtoBase({
          id: this.GetId(),
          passPercent: 79.123456,
          failChecks: 3,
          countChecks: 2,
          passChecks: 7,
          totalChecks: 17,
          skippedChecks: 1,
          errorChecks: 3
        }),
        startTime: GetDateWithOffset(-4 * i),
        endTime: GetDateWithOffset((-4 * i) + 1),
        files: [],
        checkSetId: checkSet.id,
        jobName: `Sample Job ${i}`,
        runDate: GetDateWithOffset(-4 * i),
      });

      summary.files!.push(this.reportModel);

      const report = new AvtV1ReportDto();
      report.init({
        id: summary.summaryInfo!.id,
        startTime: summary.startTime,
        endTime: summary.endTime,
        models: summary.files,
        checkSet: checkSet,
        runDate: summary.runDate,
        fileResults: []
      });
      this.GenerateStructure(report, 8, 6, 12, true);

      summaries.push(summary);
      reports.push(report);
    }

    const summaryNoPassFail = new AvtReportSummaryDto({
      summaryInfo: new AvtReportDtoBase({
        id: this.GetId(),
        passPercent: 100,
        failChecks: 0,
        countChecks: 20,
        passChecks: 0,
        totalChecks: 24,
        skippedChecks: 4,
        errorChecks: 0
      }),
      startTime: GetDateWithOffset(-4),
      endTime: GetDateWithOffset(-3),
      files: [],
      jobName: `Sample Job No Pass Fail`,
      checkSetId: this.sampleCheckSets[0].id,
      runDate: GetDateWithOffset(-4),
    });

    summaryNoPassFail.files!.push(this.reportModel);

    const reportNoPassFail = new AvtV1ReportDto();
    reportNoPassFail.init({
      id: summaryNoPassFail.summaryInfo!.id,
      startTime: summaryNoPassFail.startTime,
      endTime: summaryNoPassFail.endTime,
      models: summaryNoPassFail.files,
      checkSet: this.sampleCheckSets[0],
      runDate: summaryNoPassFail.runDate,
      fileResults: []
    });
    this.GenerateStructure(reportNoPassFail, 3, 3, 6, false);

    summaries.push(summaryNoPassFail);

    return {reports: reports, summaries: summaries};
  }

  private CreateSampleCheckSets(quantity: number = 3): CheckSet[] {
    const checkSets: CheckSet[] = [];

    for (let i = 0; i < quantity; i++) {
      const config = new CheckSet({
        id: this.GetId(),
        author: `You`,
        lastUpdate: this.GetRandomDate(),
        description: `This is your checkset.... It's #${i}`,
        name: `Private title ${i}`,
        imagePath: 'assets/images/Default_Checkset.png',
        url: 'some/fake/location/file.xml',
        source: CheckSetSource.Url
      });
      checkSets.push(config);
    }

    return checkSets;
  }

  private GenerateStructure(report: AvtV1ReportDto, headings: number, sections: number, checks: number, allowPassFail: boolean): void {
    const file = this.GenerateFile(report, headings, sections, checks, allowPassFail);
    report.fileResults!.push(file);
  }

  private GenerateFile(report: AvtV1ReportDto,
                       headings: number,
                       sections: number,
                       checks: number,
                       allowPassFail: boolean): AvtV1ReportFileDto {
    const file = new AvtV1ReportFileDto();
    file.init({
      model: this.GetSampleModel('Sample Model'),
      headings: []
    });

    for (let i = 0; i < headings; i++) {
      const heading = this.GenerateHeading(i, report, sections, checks, allowPassFail);
      file.headings!.push(heading);
    }

    return file;
  }

  private GenerateHeading(
    i: number,
    report: AvtV1ReportDto,
    sections: number,
    checks: number,
    allowPassFail: boolean
  ): ReportHeadingDto {
    const heading = new ReportHeadingDto();
    heading.init({
      id: this.GetId(),
      headerValue: `Heading ${i} - ${report.checkSet!.name}`,
      failChecks: allowPassFail ? 3 : 0,
      skippedChecks: 2,
      passChecks: allowPassFail ? 7 : 0,
      passPercent: allowPassFail ? 75.98756 : 100,
      countChecks: 5,
      totalChecks: 17,
      sections: []
    });

    for (let s = 0; s < sections; s++) {
      const section = this.GenerateSection(s, checks, allowPassFail);
      heading.sections!.push(section);
    }

    return heading;
  }

  private GenerateSection(s: number, checks: number, allowPassFail: boolean): AvtV1ReportSectionDto {
    const section = new AvtV1ReportSectionDto();
    section.init({
      id: this.GetId(),
      title: `Section ${s} Title`,
      name: `Section ${s} Name`,
      description: `This is a description of section ${s} and all the checks it contains.`,
      failChecks: allowPassFail ? 3 : 0,
      skippedChecks: 2,
      passChecks: allowPassFail ? 7 : 0,
      passPercent: allowPassFail ? 95.9635214 : 100,
      countChecks: 5,
      totalChecks: 17,
      checks: [],
      subSections: []
    });

    for (let c = 0; c < checks; c++) {
      const check = this.GenerateCheck(c);
      section.checks!.push(check);
    }

    return section;
  }

  private GenerateCheck(c: number): AvtV1ReportCheckDto {
    const result = new AvtV1ReportCheckResultDto();
    result.init({
      checkWasRun: c !== 3,
      errorMessage: c === 4 ? `Error Message ${c}` : undefined,
      listItems: [],
      resultCustomValue: c === 3 ? `Result Message ${c}` : undefined
    });
    if (c === 1 || c === 2) {
      for (let e = 0; e < 5; e++) {
        const element = this.GenerateElement(e);
        result.listItems!.push(element);
      }
    }
    const check = new AvtV1ReportCheckDto();
    check.init({
      id: this.GetId(),
      name: `Check ${c}`,
      description: `Description ${c}`,
      checkType: c === 2 ? ReportCheckType.RasterImages : ReportCheckType.Custom,
      checkResult: result,
      failureMessage: c === 1 ? `Sample fail message ${c}` : undefined,
      resultCondition: c === 1 || c === 2
        ? ReportCheckResultCondition.FailMatchingElements
        : ReportCheckResultCondition.FailNoElements
    });
    return check;
  }

  private GenerateElement(e: number): AvtV1ReportElementDataDto {
    const element = new AvtV1ReportElementDataDto();
    element.init({
      categoryName: `Category ${e}`,
      id: e,
      name: `Element Name ${e}`,
      typeName: `Element Type ${e}`,
      value: `Sample Value ${e}`,
      familyName: `Sample Family ${e}`,
      itemsCount: e,
    });
    return element;
  }

  private CreateProjectWiseCredentials(quantity: number): ProjectWiseCredentialWithSecret[] {
    const credentials: ProjectWiseCredentialWithSecret[] = [];

    for (let i = 0; i < quantity; i++) {
      credentials.push(new ProjectWiseCredentialWithSecret({
        key: `PWKey${i}`,
        id: this.GetId(),
        password: `PWPassword${i}`,
        username: `PWUser${i}`
      }));
    }

    return credentials;
  }

  private CreateProjectWiseConfigurations(credential: ProjectWiseCredential, quantity: number): ProjectWiseConfiguration[] {
    const configurations: ProjectWiseConfiguration[] = [];

    for (let i = 0; i < quantity; i++) {
      configurations.push(new ProjectWiseConfiguration({
        id: this.GetId(),
        credentialId: credential.id,
        serverAddress: `https://server.com/${credential.key}/${i}`,
        repositoryId: `repoId${i}`,
        repositoryName: `Credential ${credential.key} Sample Repository ${i}`,
      }));
    }

    return configurations;
  }

  private GetRandomDate(isInPast: boolean = true, maxOffsetDays: number = 10): Date {
    const offsetDays = Math.floor(Math.random() * maxOffsetDays);
    return isInPast ? GetDateWithOffset(offsetDays * -1) : GetDateWithOffset(offsetDays);
  }
}
