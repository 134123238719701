////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import React from 'react';
import {JobUsageData} from '../clients/Classes';
import {GetPropertyDisplayString} from '../Utility';
import {ConvertTextTruncation} from '../converters/ConvertTextTruncation';
import {ColumnLeft, ColumnRight, FlexRow} from '../CommonStyledComponents';
import Theme from "@adsk/alloy-react-theme";
import {IconButton} from "@adsk/alloy-react-button";
import {ClipboardIcon} from "@adsk/alloy-react-icon";

const UsageDetail = ({detailObject, prefix, truncateValues, allowClipboard = true}: {
  detailObject: JobUsageData,
  prefix?: string | undefined,
  truncateValues: boolean,
  allowClipboard?: boolean
}) => {
  return (
    <>
      {
        Object.keys(detailObject).map(k => {
          // @ts-ignore
          if (detailObject != null && detailObject[k] instanceof Object) {
            const newPrefix = prefix == null ? `${k}.` : `${prefix}${k}.`;
            // @ts-ignore
            return <UsageDetail detailObject={detailObject[k]} prefix={newPrefix} truncateValues={truncateValues}
                                allowClipboard={allowClipboard}/>
          }
          const propertyValue = GetPropertyDisplayString(detailObject, k);
          const isLink = propertyValue != null && propertyValue.toLowerCase().startsWith('http');
          const truncatedString = propertyValue == null ? '' : ConvertTextTruncation.Convert(propertyValue);
          return (
            <FlexRow>
              <ColumnLeft style={Theme.typography.bodyMediumBold}><span
                style={{marginRight: '1em'}}>{prefix}{k}</span></ColumnLeft>
              <ColumnRight style={Theme.typography.bodyMedium}>
                {isLink && <a href={propertyValue}>{truncateValues ? truncatedString : propertyValue}</a>}
                {!isLink && <span>{truncateValues ? truncatedString : propertyValue}</span>}
              </ColumnRight>
              {allowClipboard &&
                <IconButton
                  style={{alignSelf: 'center', minWidth: 0, marginLeft: '1em'}}
                  onClick={() => {
                    if (propertyValue == null) {
                      return;
                    }
                    navigator.clipboard.writeText(propertyValue)
                      .then(() => alert('Full property value was copied to clipboard'));
                  }}>
                  <ClipboardIcon/>
                </IconButton>
              }
            </FlexRow>
          );
        })
      }
    </>
  );
};

export default UsageDetail;
