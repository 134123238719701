////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import React, {useMemo, useReducer} from 'react';
import {UsageDataService} from "../services/UsageDataService";
import {reducer} from "../components/reducers/UsageReducer";
import {UsageState} from "../components/states/UsageState";
import {
  BlueButton, CenteringContainer,
  ColumnLeft,
  ColumnRight,
  ContentWrapper,
  FlexColumn,
  FlexFill,
  FlexRow,
  FlexRowCentered
} from '../CommonStyledComponents';
import UsageDataTree from "../components/UsageDataTree";
import {UsageActions} from "../Enums";
import IdInput from "../components/IdInput";
import {CombineUsageSummaries, GetErrorMessage} from '../Utility';
import {ConvertDuration} from "../converters/ConvertDuration";
import {UsageDataTranslator} from "../dataModel/translators/UsageDataTranslator";
import {PageSizeService} from "../services/PageSizeService";
import Theme from "@adsk/alloy-react-theme";
import Checkbox, {CheckboxState} from "@adsk/alloy-react-checkbox";
import {DatePicker} from "@adsk/alloy-react-date-picker";
import {ArrowRotateTwoIcon, PlusCircleIcon} from "@adsk/alloy-react-icon";
import ProgressRing from "@adsk/alloy-react-progress-ring";
import Illustration from "@adsk/alloy-react-illustration";
import {JobRunStatusType} from "../clients/Classes";
import {
  DropdownMenu,
  MultiDropdownTokenControl,
  useMultiDropdown
} from "@adsk/alloy-react-dropdown";

const service = new UsageDataService();

const pageSize = PageSizeService.GetPageSize('usage');
let paginationToken: string | undefined = undefined;

const Usage = () => {
  const [state, dispatch] = useReducer(reducer, new UsageState());

  function getSummary(isFirstRun: boolean): void {
    const messages: string[] = [];

    if (state.useStartDate && state.startDate == null) {
      messages.push('Start date is not set');
    }

    if (state.useEndDate && state.endDate == null) {
      messages.push('End date is not set');
    }

    if (messages.length > 0) {
      alert(`You must fix the following before you can filter:\n\n${messages.join('\n')}`);
      return;
    }

    const customerIds = state.customerIds.length > 0 ? state.customerIds : undefined;
    const jobIds = state.jobIds.length > 0 ? state.jobIds : undefined;
    const userIds = state.userIds.length > 0 ? state.userIds : undefined;
    const start = state.useStartDate ? state.startDate : undefined;
    const end = state.useEndDate ? state.endDate : undefined;
    const statuses = state.statuses.length > 0 ? state.statuses : undefined;

    dispatch({
      type: UsageActions.multipleActions,
      payload: {loading: isFirstRun, loadingMoreData: !isFirstRun}
    });

    service.GetUsageSummary(statuses, customerIds, userIds, jobIds, start ?? undefined, end ?? undefined, paginationToken, pageSize)
      .then(apiSummary => {
          paginationToken = apiSummary.isDone ? undefined : apiSummary.paginationData?.paginationToken;
          const data = UsageDataTranslator.TranslateUsageSummary(apiSummary.data!);
          const finalResult = isFirstRun ? data : CombineUsageSummaries([data, state.result!]);

          dispatch({
            type: UsageActions.multipleActions, payload: {
              dataItems: finalResult.CustomerData,
              loading: false,
              loadingMoreData: false,
              hasMoreData: !apiSummary.isDone,
              result: finalResult,
            }
          });
        },
        er => {
          onError(er, 'Filter Data');
          dispatch({type: UsageActions.loading, payload: false});
        });
  }

  function checkChanged(newState: CheckboxState, isStart: boolean): void {
    const action = isStart ? UsageActions.useStartDate : UsageActions.useEndDate;
    dispatch({type: action, payload: newState === true});
  }

  function onDateChange(newDate: Date | null | undefined, isStart: boolean): void {
    const action = isStart ? UsageActions.startDate : UsageActions.endDate;
    dispatch({type: action, payload: newDate});
  }

  function onListChange(ids: string[], listType: string): void {
    let action: UsageActions;
    switch (listType) {
      case 'customer':
        action = UsageActions.customerIds;
        break;
      case 'user':
        action = UsageActions.userIds;
        break;
      case 'job':
        action = UsageActions.jobIds;
        break;
      default:
        return;
    }
    dispatch({type: action, payload: ids})
  }

  function onStatusChange(e: { label: string, value: string }[] | undefined): void {
    const statuses = e == null ? [] : e.map(x => JobRunStatusType[x.value as keyof typeof JobRunStatusType]);
    dispatch({type: UsageActions.statuses, payload: statuses});
  }

  function onError(error: any, operation: string): void {
    alert(GetErrorMessage(error, operation));
  }

  const statusOptions: { label: string, value: string }[] = useMemo(() => {
    const options: { label: string, value: string }[] = [];
    Object.keys(JobRunStatusType).map(k => options.push({label: k, value: k}));
    return options;
  }, []);

  const {controlProps, menuProps} = useMultiDropdown({
    items: statusOptions,
    onSelectedItemsChange: e => onStatusChange(e.selectedItems)
  });

  return (
    <ContentWrapper>
      <FlexRow style={{flex: 0}}>
        <h1 style={Theme.typography.heading1}>Usage</h1>
      </FlexRow>
      <FlexColumn>
        <FlexRow style={{flex: 0}}>
          <ColumnLeft style={Theme.typography.bodyMedium}>Customer Ids</ColumnLeft>
          <ColumnRight style={Theme.typography.bodyMedium}>
            <IdInput ids={state.customerIds}
                     onChange={ids => onListChange(ids, 'customer')} onCommit={() => getSummary(true)}/>
          </ColumnRight>
        </FlexRow>
        <FlexRow style={{flex: 0}}>
          <ColumnLeft style={Theme.typography.bodyMedium}>User Ids</ColumnLeft>
          <ColumnRight style={Theme.typography.bodyMedium}>
            <IdInput ids={state.userIds} onChange={ids => onListChange(ids, 'user')} onCommit={() => getSummary(true)}/>
          </ColumnRight>
        </FlexRow>
        <FlexRow style={{flex: 0}}>
          <ColumnLeft style={Theme.typography.bodyMedium}>Job Ids</ColumnLeft>
          <ColumnRight style={Theme.typography.bodyMedium}>
            <IdInput ids={state.jobIds} onChange={ids => onListChange(ids, 'job')} onCommit={() => getSummary(true)}/>
          </ColumnRight>
        </FlexRow>
        <FlexRow style={{flex: 0}}>
          <ColumnLeft style={Theme.typography.bodyMedium}>Statuses</ColumnLeft>
          <ColumnRight style={Theme.typography.bodyMedium}>
            <div style={{width: '100%'}}>
              <MultiDropdownTokenControl {...controlProps}/>
              <DropdownMenu {...menuProps}/>
            </div>
          </ColumnRight>
        </FlexRow>
        <FlexRow style={{flex: 0}}>
          <ColumnLeft style={Theme.typography.bodyMedium}>
            <FlexRowCentered>
              <Checkbox
                checked={state.useStartDate}
                onChange={value => checkChanged(value, true)}/>
              <label style={{marginLeft: '0.5em'}}>Start Date</label>
            </FlexRowCentered>
          </ColumnLeft>
          <ColumnRight style={Theme.typography.bodyMedium}>
            <DatePicker
              value={state.startDate}
              disabled={!state.useStartDate}
              onChange={value => onDateChange(value.date, true)}/>
          </ColumnRight>
        </FlexRow>
        <FlexRow style={{flex: 0}}>
          <ColumnLeft style={Theme.typography.bodyMedium}>
            <FlexRowCentered>
              <Checkbox
                checked={state.useEndDate}
                onChange={value => checkChanged(value, false)}/>
              <label style={{marginLeft: '0.5em'}}>End Date</label>
            </FlexRowCentered>
          </ColumnLeft>
          <ColumnRight style={Theme.typography.bodyMedium}>
            <DatePicker
              value={state.endDate}
              disabled={!state.useEndDate}
              onChange={value => onDateChange(value.date, false)}/>
          </ColumnRight>
        </FlexRow>
        <FlexRowCentered style={{marginBottom: '2em'}}>
          <BlueButton onClick={() => getSummary(true)} style={{marginRight: '1em'}}>
            <FlexRowCentered>
              <ArrowRotateTwoIcon style={{marginRight: '0.5em'}}/>
              <span style={Theme.typography.labelMedium}>Refresh Data</span>
            </FlexRowCentered>
          </BlueButton>
          <BlueButton onClick={() => getSummary(false)}
                      disabled={!state.hasMoreData || state.loadingMoreData || state.loading}>
            <FlexRowCentered>
              <PlusCircleIcon style={{marginRight: '0.5em'}}/>
              <span style={Theme.typography.labelMedium}>Load More</span>
            </FlexRowCentered>
          </BlueButton>
          {state.loadingMoreData && <ProgressRing size={'small'} style={{marginLeft: '1em'}}/>}
        </FlexRowCentered>
        <FlexFill style={{overflowY: 'auto'}}>
          {
            state.result && !state.loading &&
              <>
                  <FlexRow style={{flex: 0}}>
                      <span style={Theme.typography.bodyMediumBold}>Overall Stats:</span>
                      <span style={{width: '1em'}}/>
                      <span
                          style={Theme.typography.bodyMedium}>{state.result.TotalRunCount} total runs / {ConvertDuration.Convert(state.result.TotalRunTimeSeconds)}</span>
                  </FlexRow>
                  <UsageDataTree topItems={state.dataItems}/>
              </>
          }
          {
            !state.loading && !state.result &&
              <CenteringContainer style={{flexDirection: 'column'}}>
                  <Illustration type={'pagesTextGrey'} height={200} width={200}/>
                  <p style={Theme.typography.bodyLarge}>No Usage Data to Display</p>
              </CenteringContainer>
          }
          {
            state.loading &&
              <CenteringContainer>
                  <ProgressRing size={'large'}/>
              </CenteringContainer>
          }
        </FlexFill>
      </FlexColumn>
    </ContentWrapper>
  );
};

export default Usage;
